/* Set default height for root containers */
html,
body,
#root {
  height: 100%;
}

html,
body {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

#root {
  overflow: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default padding */
ul[class],
ol[class] {
  padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
  font-family: "Roboto";
  font-size: 16px;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Remove list styles on ul, ol elements with a class attribute */
ul[class],
ol[class] {
  list-style: none;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
  max-width: 100%;
  display: block;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
  margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove clear button for inputs in Edge */
input::-ms-clear {
  display: none;
}

/* Remove dotted outline in Firefox on focus */
a::-moz-focus-inner,
button::-moz-focus-inner,
select::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
}

/* Set the correct font family for bold tags */
b,
strong {
  font-family: Roboto-Bold;
}

/* Hide input type number arrows  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

/* remove content jumping caused by scrollbar (desktop only) */
@media screen and (min-width: 960px) {
  html {
    overflow-y: auto;
    overflow-x: hidden;
    margin-right: calc(100% - 100vw);
  }
}

/* define default tooltip theme */
.tippy-box[data-theme~="default"] {
  background-color: #ffffff;
  border: 1px solid #c4c4c4;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;

  font-family: "Roboto";
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.5);

  overflow-wrap: break-word;
  word-break: break-word;
}

.tippy-box[data-theme~="default"] > .tippy-content {
  padding: 20px;
}

/* define default tooltip theme */
.tippy-box[data-theme~="menu"] {
  background-color: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);
  border-radius: 6px;

  font-family: "Roboto";
  font-size: 14px;
  line-height: 18px;
}

.tippy-box[data-theme~="menu"] > .tippy-content {
  padding: 0;
}
